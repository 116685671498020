<template>
    <LFeatureGroup :visible="showTrackerLayer">
        <component :is="markerClusterComponent">
            <template
                v-for="{ component, tracker } in filteredTrackers.cluster"
            >
                <component
                    :is="component"
                    :key="tracker.id"
                    :asset="tracker"
                    :is-selected="
                        activeTracker && activeTracker.id === tracker.id
                    "
                    :interactive="interactive"
                    @markerClicked="$emit('markerClicked', tracker.id)"
                />
            </template>
        </component>

        <template v-for="{ component, tracker } in filteredTrackers.noCluster">
            <component
                :is="component"
                :key="tracker.id"
                :asset="tracker"
                :is-selected="activeTracker && activeTracker.id === tracker.id"
                :interactive="interactive"
                @markerClicked="$emit('markerClicked', tracker.id)"
            />
        </template>
    </LFeatureGroup>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { LFeatureGroup } from 'vue2-leaflet'

import AirQualityMarker from './AirQualityMarker'
import AssetMarkerCluster from './AssetMarkerCluster'
import DynamicBinMarker from './asset-types/dynamic-bin/DynamicBinMarker'
import GenericMarker from './GenericMarker'
import LevelAssetMarker from './asset-types/level/LevelAssetMarker'
import MachineMarker from './MachineMarker'
import ParkingSpaceMarker from './ParkingSpaceMarker'
import SbbAssetMarkerCluster from './asset-types/sbb-bin/SbbAssetMarkerCluster'
import SbbBinMarker from './asset-types/sbb-bin/SbbBinMarker'
import TruckMarker from './TruckMarker'

const markerMapping = {
    'air-quality': AirQualityMarker,
    'dynamic-bin': DynamicBinMarker,
    'dynamic-bin-collection': DynamicBinMarker,
    'parking-space': ParkingSpaceMarker,
    'sbb-bin': SbbBinMarker,
    level: LevelAssetMarker,
    machine: MachineMarker,
    truck: TruckMarker,
}

export default {
    name: 'TrackerLayer',
    components: {
        LFeatureGroup,
    },
    props: {
        // Allows to exlude markers from clustering.
        // Provide Array of either Tracker IDs or Tracker Objects (with property 'id').
        excludedFromClustering: {
            type: Array,
            default: () => [],
        },
        interactive: {
            type: Boolean,
            default: true,
        },
        onlyIds: {
            type: Array,
            default: null,
        },
        trackers: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            showTrackerLayer: false,
        }
    },
    computed: {
        ...mapState('map', ['simpleMarkersEnabled']),
        ...mapState('sharing', ['activeSharedTracker']),
        ...mapState('tracker', [
            'activeTrackerOnMap',
            'filterParams',
            'filteredTrackerIds',
            'shouldAdjustMapOnFilterChange',
        ]),
        ...mapGetters('tracker', ['assetsById']),
        activeTracker() {
            return this.activeSharedTracker ?? this.activeTrackerOnMap
        },
        filteredTrackers() {
            const trackers =
                this.filteredTrackerIds?.reduce((acc, id) =>
                    this.assetsById[id] ? [...acc, this.assetsById[id]] : acc
                ) ?? this.trackers
            const trackersToCluster = []
            const trackersNotToCluster = []

            for (const tracker of trackers) {
                const data = {
                    component: this.simpleMarkersEnabled
                        ? GenericMarker
                        : markerMapping[
                              tracker.asset_details?.asset_type_type
                          ] || GenericMarker,
                    tracker,
                }
                if (this.excludedIDs.includes(tracker.id)) {
                    trackersNotToCluster.push(data)
                } else {
                    trackersToCluster.push(data)
                }
            }

            return {
                cluster: trackersToCluster,
                noCluster: trackersNotToCluster,
            }
        },
        excludedIDs() {
            return this.excludedFromClustering
                .filter(Boolean)
                .map(item => (item > 0 ? item : item.id))
        },
        markerClusterComponent() {
            return process.env.VUE_APP_ENVIRONMENT_NAME === 'sbb'
                ? SbbAssetMarkerCluster
                : AssetMarkerCluster
        },
    },
    watch: {
        activeTracker: {
            immediate: true,
            handler(tracker) {
                if (tracker) {
                    this.$emit('alignMap', [
                        tracker.asset_details
                            ? [
                                  tracker.asset_details.position.latitude,
                                  tracker.asset_details.position.longitude,
                              ]
                            : [tracker.position.lat, tracker.position.lng],
                    ])
                }
            },
        },
        filterParams() {
            if (this.shouldAdjustMapOnFilterChange) {
                this.$emit(
                    'alignMap',
                    this.trackers.map(tracker => [
                        tracker.asset_details.position.latitude,
                        tracker.asset_details.position.longitude,
                    ])
                )
            }
        },
    },
    mounted() {
        // By setting the Leaflet FeatureGroup to invisible at first, all the nested components (markers) add their
        // layers to this FeatureGroup without it being rendered in the DOM. Then by setting the FeatureGroup
        // visible in the next tick, all the markers get added to the map at once.
        // This seems way faster. Otherwise every marker gets rendered independently and the map updates hundreds of times.
        this.$nextTick(() => {
            this.showTrackerLayer = true
        })
    },
}
</script>
