<template>
    <LMarkerCluster
        ref="instance"
        :key="'AssetMarkerCluster' + key"
        :options="clusterOptions"
        @ready="handleClusterInit"
    >
        <slot />
    </LMarkerCluster>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { DivIcon } from 'leaflet'
import LMarkerCluster from 'vue2-leaflet-markercluster'
import clsx from 'clsx'

export default {
    components: {
        LMarkerCluster,
    },
    data() {
        return {
            key: 1,
        }
    },
    computed: {
        ...mapState('map', ['clusteringEnabled']),
        ...mapGetters('tracker', ['isTrackerCountHigh']),
        clusterOptions() {
            return {
                iconCreateFunction: this.createClusterIcon,
                maxClusterRadius: 120,
                removeOutsideVisibleBounds: false,
                showCoverageOnHover: false,
                spiderfyDistanceMultiplier: 3.5,
                spiderfyOnMaxZoom: true,
                zoomToBoundsOnClick: true,
                ...(!this.clusteringEnabled && {
                    disableClusteringAtZoom: 0,
                }),
                ...(this.isTrackerCountHigh && {
                    maxClusterRadius: 160,
                }),
            }
        },
    },
    watch: {
        clusteringEnabled() {
            // Key-changing workaround to force cluster options update on the
            // 'clusteringEnabled' state value chnage
            this.key++
        },
    },
    methods: {
        createClusterIcon(cluster) {
            const children = cluster.getAllChildMarkers()
            const includesWarningIcon = children.some(
                child => child.options.icon.options.warnIconInstance
            )
            const includesErrorIcon = children.some(
                child => child.options.icon.options.errorIconInstance
            )

            return new DivIcon({
                className: clsx(
                    'sbb-marker-cluster',
                    includesErrorIcon
                        ? 'sbb-marker-cluster--error'
                        : includesWarningIcon
                        ? 'sbb-marker-cluster--warning'
                        : 'sbb-marker-cluster--ok'
                ),
                iconSize: [10, 10],
            })
        },
        handleClusterInit(cluster) {
            cluster._getExpandedVisibleBounds = () => cluster._map.getBounds()
        },
    },
}
</script>

<style lang="scss">
.sbb-marker-cluster {
    border-radius: 50%;
    box-shadow: 0 0 0px 2px #fff, 0 0 4px 2px rgba(0, 0, 0, 0.75),
        0 0 2px rgba(0, 0, 0, 0.25) inset;

    &--error {
        background-color: $color-red;
    }

    &--ok {
        background-color: $color-green;
    }

    &--warning {
        background-color: $color-orange;
    }
}
</style>
